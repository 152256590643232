import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Home_Assistant_Tutorials/PrimaryBox.jsx';
import PrimaryBox1 from 'components/Software/Linux/Home_Assistant/PrimaryBox1';
import PrimaryBox2 from 'components/Software/Linux/Home_Assistant/PrimaryBox2';
import DangerBox from 'components/Software/Linux/Home_Assistant/DangerBox';
import NavButtons from 'components/Software/Linux/NavButtons';
import ForumBox from 'components/Software/Linux/Home_Assistant/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Home Assistant on Debian",
  "path": "/Software/Linux/Home_Assistant/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "Home Assistant is a open source home automation that puts local control and privacy first. Powered by a worldwide community of tinkerers and DIY enthusiasts. Perfect to run on a Raspberry Pi or a local server. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_HomeAssistant.png",
  "social": "/images/Search/AU_SearchThumb_HomeAssistant.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_HomeAssistant_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Home Assistant"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Home Assistant on Debian' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Home Assistant is a open source home automation that puts local control and privacy first. Powered by a worldwide community of tinkerers and DIY enthusiasts. Perfect to run on a Raspberry Pi or a local server. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_HomeAssistant.png' twitter='/images/Search/AU_SearchThumb_HomeAssistant.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Linux/Home_Assistant/' locationFR='/fr/Software/Linux/Home_Assistant/' crumbLabel="Home Assistant" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#linux",
        "aria-label": "linux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LINUX`}</h2>
    <h3 {...{
      "id": "home-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#home-assistant",
        "aria-label": "home assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Assistant`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation-of-home-assistant-on-a-debian-stretch-server"
        }}>{`Installation of Home Assistant on a Debian Stretch Server`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#installation-of-dependencies"
            }}>{`Installation of dependencies:`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#create-a-user-and-group"
            }}>{`Create a user and group`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#install-home-assistant"
            }}>{`Install Home Assistant`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#run-home-assistant-as-a-service"
            }}>{`Run Home Assistant as a Service`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-home-assistant"
        }}>{`Configuring Home Assistant`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-the-mqtt-broker"
            }}>{`Adding the MQTT Broker`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-home-assistant-lovelace-ui"
        }}>{`The Home Assistant Lovelace UI`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-your-cameras-live-video"
            }}>{`Adding your camera's Live Video`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-home-assistant-as-alarmserver-for-your-instar-camera"
            }}>{`Using Home Assistant as Alarmserver for your INSTAR Camera`}</a></li>
        </ul>
      </li>
    </ul>
    <PrimaryBox1 mdxType="PrimaryBox1" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "installation-of-home-assistant-on-a-debian-stretch-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation-of-home-assistant-on-a-debian-stretch-server",
        "aria-label": "installation of home assistant on a debian stretch server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation of Home Assistant on a Debian Stretch Server`}</h2>
    <h3 {...{
      "id": "installation-of-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installation-of-dependencies",
        "aria-label": "installation of dependencies permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation of dependencies:`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` update
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` python3-dev python3-pip python3-venv
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` pip3 `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` --upgrade virtualenv`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/52282efa44a81923c20441fe2f13ee39/e9beb/HomeAssistant_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA6klEQVQI1xXLbU+CQAAAYP6KaZzHcdx5B5MXGSByHO9R6khtWZtu/YBWM8NW/fDW8/1R9k32kMWHtnwsko2IuiTskrD2LGnREMPEJJXNqTaC2tAwVB0DAK40bYR0FRtj5dCWT7Xc1/lWxsvIuw3dTkSNP20DpxPRehHs0rmMZt7M9FwWBM7U5o7DbZu5nqVk5eL48vz1ez71b5ef/vT52n9/nC/veZ3GIsyKxJ+7/v9hnGNuGqZFCEWUIjpByk0jV8vqfrParsvdnahqWeRxKgLOdDZBlIwNrEI4JBQSAnWsMqYjdA3AQAWDPzwTPb18daZhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52282efa44a81923c20441fe2f13ee39/e4706/HomeAssistant_01.avif 230w", "/en/static/52282efa44a81923c20441fe2f13ee39/d1af7/HomeAssistant_01.avif 460w", "/en/static/52282efa44a81923c20441fe2f13ee39/b6582/HomeAssistant_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/52282efa44a81923c20441fe2f13ee39/a0b58/HomeAssistant_01.webp 230w", "/en/static/52282efa44a81923c20441fe2f13ee39/bc10c/HomeAssistant_01.webp 460w", "/en/static/52282efa44a81923c20441fe2f13ee39/87ca7/HomeAssistant_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52282efa44a81923c20441fe2f13ee39/81c8e/HomeAssistant_01.png 230w", "/en/static/52282efa44a81923c20441fe2f13ee39/08a84/HomeAssistant_01.png 460w", "/en/static/52282efa44a81923c20441fe2f13ee39/e9beb/HomeAssistant_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/52282efa44a81923c20441fe2f13ee39/e9beb/HomeAssistant_01.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "create-a-user-and-group",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-a-user-and-group",
        "aria-label": "create a user and group permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create a user and group`}</h3>
    <p>{`Give the user access to serial lines (zwave, insteon, etc):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` adduser --system homeassistant
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` addgroup homeassistant
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` adduser homeassistant dialout`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/90905c87ee7dbb6a9883de37e281954e/e9beb/HomeAssistant_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA/UlEQVQY0x3MCXKCMBQAUO5i1ZB88rMQoBqWJMoSUGzr4LQzvf81OtN3gJe8Ptd1GR7LMMXQ16e5rcfWBlvNoYtXFy6Nc2dbaaVAKuCcMDgAHBjsSbpLfuOy+bB5v3btNg7bff5a54/b9HzM99vYeSuRViw1AqRkWgNHwtg++1+S5zj0vg7tuS/KYIwvjCvNpSi60th3U+aokUJ2zJBwJFJRKRnAATFlbJ+8Tk0U+WLrtQ+Da6bg5q4eXXPzXWxrq4SVmAtASREJilRrQJFyTDknyffP87HGKYZhCvfYXFtVnU3TVidr8gKVBi6IyjMhKeeE0jepmJQ0y45Csj+WfkM+W94XdQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90905c87ee7dbb6a9883de37e281954e/e4706/HomeAssistant_02.avif 230w", "/en/static/90905c87ee7dbb6a9883de37e281954e/d1af7/HomeAssistant_02.avif 460w", "/en/static/90905c87ee7dbb6a9883de37e281954e/b6582/HomeAssistant_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/90905c87ee7dbb6a9883de37e281954e/a0b58/HomeAssistant_02.webp 230w", "/en/static/90905c87ee7dbb6a9883de37e281954e/bc10c/HomeAssistant_02.webp 460w", "/en/static/90905c87ee7dbb6a9883de37e281954e/87ca7/HomeAssistant_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90905c87ee7dbb6a9883de37e281954e/81c8e/HomeAssistant_02.png 230w", "/en/static/90905c87ee7dbb6a9883de37e281954e/08a84/HomeAssistant_02.png 460w", "/en/static/90905c87ee7dbb6a9883de37e281954e/e9beb/HomeAssistant_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/90905c87ee7dbb6a9883de37e281954e/e9beb/HomeAssistant_02.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Create a directory to install HA in and set its ownership and permissions.`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/homeassistant
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` homeassistant:homeassistant /opt/homeassistant`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bf1eb6eb79ba6ed46932166200244570/e9beb/HomeAssistant_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "7.826086956521738%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAcUlEQVQI1wXBAQ7CIAwAwL3IqAPLKAUk2yAKHQmIidH/f8O76fMdreXXqNvuUwp8pNbLuvnWuXdunWt95BJLiYgSUSxaaJTWLcbcpvGunFfmtMfwe86eBMB1FieycA9IFsgpQ2AIAM6oBagLGmkInFN/9UYfTPInqvsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf1eb6eb79ba6ed46932166200244570/e4706/HomeAssistant_03.avif 230w", "/en/static/bf1eb6eb79ba6ed46932166200244570/d1af7/HomeAssistant_03.avif 460w", "/en/static/bf1eb6eb79ba6ed46932166200244570/b6582/HomeAssistant_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bf1eb6eb79ba6ed46932166200244570/a0b58/HomeAssistant_03.webp 230w", "/en/static/bf1eb6eb79ba6ed46932166200244570/bc10c/HomeAssistant_03.webp 460w", "/en/static/bf1eb6eb79ba6ed46932166200244570/87ca7/HomeAssistant_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf1eb6eb79ba6ed46932166200244570/81c8e/HomeAssistant_03.png 230w", "/en/static/bf1eb6eb79ba6ed46932166200244570/08a84/HomeAssistant_03.png 460w", "/en/static/bf1eb6eb79ba6ed46932166200244570/e9beb/HomeAssistant_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bf1eb6eb79ba6ed46932166200244570/e9beb/HomeAssistant_03.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change to the homeassistant user to do the installs.`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`su`}</span>{` -s /bin/bash homeassistant`}</code></pre></div>
    <h3 {...{
      "id": "install-home-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#install-home-assistant",
        "aria-label": "install home assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install Home Assistant`}</h3>
    <ol>
      <li parentName="ol">{`Install a virtual env to sandbox the Home Assistant software and dependencies and activate it so further installs are done here.`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /opt/homeassistant
python3 -m venv /opt/homeassistant
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`source`}</span>{` bin/activate`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d25cc47e0de13af55bfccda653a4251/e9beb/HomeAssistant_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.521739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAARUlEQVQI12MICfHz8XENCPT29/Pw9nH18nLx8XX38nLxD/Dy9nXz9nZzcbX38bT3dLU0MzcyN9M3MtbR0VXX0VHT1FIBAAUXD1hF+lHBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d25cc47e0de13af55bfccda653a4251/e4706/HomeAssistant_04.avif 230w", "/en/static/3d25cc47e0de13af55bfccda653a4251/d1af7/HomeAssistant_04.avif 460w", "/en/static/3d25cc47e0de13af55bfccda653a4251/b6582/HomeAssistant_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d25cc47e0de13af55bfccda653a4251/a0b58/HomeAssistant_04.webp 230w", "/en/static/3d25cc47e0de13af55bfccda653a4251/bc10c/HomeAssistant_04.webp 460w", "/en/static/3d25cc47e0de13af55bfccda653a4251/87ca7/HomeAssistant_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d25cc47e0de13af55bfccda653a4251/81c8e/HomeAssistant_04.png 230w", "/en/static/3d25cc47e0de13af55bfccda653a4251/08a84/HomeAssistant_04.png 460w", "/en/static/3d25cc47e0de13af55bfccda653a4251/e9beb/HomeAssistant_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d25cc47e0de13af55bfccda653a4251/e9beb/HomeAssistant_04.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Install HA from pip. You might get some pip install errors that do not cause an issue later on:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`pip3 `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` --upgrade homeassistant`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a1528dda92c8f44f64ae7d0bab6f8076/e9beb/HomeAssistant_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACSUlEQVQozx2SV5KcMABEucraswygHAgiCyEJiTS7dpXvfxPX7G/3R7/qeskgma7kKGgnmKJ4KoWqKKa5kIQLRGjOOaA0Z6wgJEMopbQgNC+KT4Sz5N/f++/XeUX3urbv+9j9GsMyjo1Zx7hbs45K8aZhw1ANY90PtWrFODWNEk3Dk/sO26bjvu6H2w8bownBnKcPcb3ueJ5+0a3WrTF9CMbaWS/dfrgQzLL0ybJ0Wnf3tcWw6FnVFSklVs17TbWyqVkpkRSIM0BIRhmgtGAcMA4xyRLVimFSZh2WpZUSUZpjnILiAWEKwCehRVlRygChBWUF5wDjDKInpQVEaVIyqEra16ytaMVxLbBgAOO0KB5cIErehxGSU5rLEgsBMX4inDH6ThLTqajHMI92bE3X+bG3y1BVSArQNLyu6Q8kADBF6AnAA8JPjFMI0yz/nYS43q94veJ5+eN0Ybf7aY/dHod7V/e273ZZeq07awfnRu8mrbtZd0rJ5Dj9cbhtM+fpvJ3XdVpM37VN05TvZcVVKzgHUkIhoFJMlkTINz9lRRJ3e57u9QrX7fXcWzt7PzUNYwxygepK5sUjzz8QfDyzX8/nBwAPAFOIM4Seyddru97A/vv78Na+7uO83Dz3y2S6vprnvixJWaK6onXDCQVSYsZRWRFC8uTHiiXG9Tzdvtv9sM5N1k5bMN7rLa5bXMO+hmC2bfFee6+tm62bu65M/vw5ztPd9/b9Fe97i9E4NxszbEFPutuWqqtho0RZ4qqiw1AzBhH+sYXD/6kxslRuldxQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1528dda92c8f44f64ae7d0bab6f8076/e4706/HomeAssistant_05.avif 230w", "/en/static/a1528dda92c8f44f64ae7d0bab6f8076/d1af7/HomeAssistant_05.avif 460w", "/en/static/a1528dda92c8f44f64ae7d0bab6f8076/b6582/HomeAssistant_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a1528dda92c8f44f64ae7d0bab6f8076/a0b58/HomeAssistant_05.webp 230w", "/en/static/a1528dda92c8f44f64ae7d0bab6f8076/bc10c/HomeAssistant_05.webp 460w", "/en/static/a1528dda92c8f44f64ae7d0bab6f8076/87ca7/HomeAssistant_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1528dda92c8f44f64ae7d0bab6f8076/81c8e/HomeAssistant_05.png 230w", "/en/static/a1528dda92c8f44f64ae7d0bab6f8076/08a84/HomeAssistant_05.png 460w", "/en/static/a1528dda92c8f44f64ae7d0bab6f8076/e9beb/HomeAssistant_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a1528dda92c8f44f64ae7d0bab6f8076/e9beb/HomeAssistant_05.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Open another terminal and login with your default (sudo enabled) user and open the Home Assistant port `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8123`}</code>{` in your Firewall:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` firewall-cmd --permanent --zone`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`public --add-port`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8123`}</span>{`/tcp
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` firewall-cmd --reload
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` firewall-cmd --list-all`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/77f7e2ea22589e6a68be6c0d443285f3/e9beb/HomeAssistant_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABlklEQVQY0xWQ2W7aQABF/QRexzYtYbUaSNqoLXjBs49nxisGLJKKVu3//0ll6bzfc48Rv1+/9eeo7CPdbVWzYGoj6zlVS17t26sPi5XqVrKZYbks6oWoF6IKsQyIdk/M4P/+/LgNu3aIdLsS5UbWW1k/Mb3gel3UbsbMGDkpC4kGSLk593LhZMxH0smocbw/9nW/GzmHWHgJthI8OUArwU5KzBiDvACwmNESQGGn1EcypCrA0k6p8fU8JPdf+6aPym4pqhUv/ZzPiPrMSjejTkpnrPTGQQ6gcE8MQAFQ4WTUSonxXPVv/fByHvbt7bW77ZrLrr68XT9eumElyrVsvlT9dszRRdV5o9o5UUtezpn2cm68drfk/fH9cn8i2kppgKQZk2lCzIQAOAo7GQuw8nLh5WIaIytGPuRORiYHZPy8P+Dvv4fhI8QKIDkj0h0Nx5MuFAFRPlYB1QAWdkLsBE0O0E6JlzM7Y8Zzc4l0u5btWpVLWdgpA1CGtAyI8nJuJcQn+hOr7JROj9DLmZOxyRGZMXZO7D+3uled4uMdUwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77f7e2ea22589e6a68be6c0d443285f3/e4706/HomeAssistant_06.avif 230w", "/en/static/77f7e2ea22589e6a68be6c0d443285f3/d1af7/HomeAssistant_06.avif 460w", "/en/static/77f7e2ea22589e6a68be6c0d443285f3/b6582/HomeAssistant_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/77f7e2ea22589e6a68be6c0d443285f3/a0b58/HomeAssistant_06.webp 230w", "/en/static/77f7e2ea22589e6a68be6c0d443285f3/bc10c/HomeAssistant_06.webp 460w", "/en/static/77f7e2ea22589e6a68be6c0d443285f3/87ca7/HomeAssistant_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77f7e2ea22589e6a68be6c0d443285f3/81c8e/HomeAssistant_06.png 230w", "/en/static/77f7e2ea22589e6a68be6c0d443285f3/08a84/HomeAssistant_06.png 460w", "/en/static/77f7e2ea22589e6a68be6c0d443285f3/e9beb/HomeAssistant_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/77f7e2ea22589e6a68be6c0d443285f3/e9beb/HomeAssistant_06.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then switch back to the homeassistant user.`}</p>
    <ol>
      <li parentName="ol">{`To keep all the config and log files in the same directory (rather than having them in /home/homassistant) we can run Home Assistant with the following command:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` config
./bin/hass -c /opt/homeassistant/config --log-file /opt/homeassistant/hass.log`}</code></pre></div>
    <p>{`Home Assistant should install a few things and make a default config file (let it run for a little while - it takes a bit on the first start up). Hit ctrl-c to stop it. The config directory now contains a bunch of sample config files for you to edit.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5cdb047bea7cc5f631db5b943817a836/e9beb/HomeAssistant_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.608695652173912%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAlklEQVQI1x3MWw6CMBBAUVZkhMrQF5Ja6GOYmSLG/W/F4N9NTnI74m3bnu3A89xFyufbRHKTjLgSZ6LErSJGLCGXUGu0btRGwdRrozrilNKyU2LOxxvbgcRJJHMrxOnqVlnKTtsafXg552BQN+fBWNXVGsv/WmqYF+09OPfwfnQetO6tVdYqgLufJ22UsRcB9JMeRrj/AJTJLo2NhvKyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5cdb047bea7cc5f631db5b943817a836/e4706/HomeAssistant_07.avif 230w", "/en/static/5cdb047bea7cc5f631db5b943817a836/d1af7/HomeAssistant_07.avif 460w", "/en/static/5cdb047bea7cc5f631db5b943817a836/b6582/HomeAssistant_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5cdb047bea7cc5f631db5b943817a836/a0b58/HomeAssistant_07.webp 230w", "/en/static/5cdb047bea7cc5f631db5b943817a836/bc10c/HomeAssistant_07.webp 460w", "/en/static/5cdb047bea7cc5f631db5b943817a836/87ca7/HomeAssistant_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5cdb047bea7cc5f631db5b943817a836/81c8e/HomeAssistant_07.png 230w", "/en/static/5cdb047bea7cc5f631db5b943817a836/08a84/HomeAssistant_07.png 460w", "/en/static/5cdb047bea7cc5f631db5b943817a836/e9beb/HomeAssistant_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5cdb047bea7cc5f631db5b943817a836/e9beb/HomeAssistant_07.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`You can now access Home Assistants web interface by opening the following URL (exchange the IP address `}<strong parentName="li">{`192.168.2.111`}</strong>{` with the IP of your Linux Server) inside your webbrowser `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.111:8123/`}</code>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/64f9e4ad3a34e4364ac94aff552c2473/e9beb/HomeAssistant_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABOklEQVQoz5WQ7U7CMBSGuVsT42XpP38YY7wART4KbEO5BCltWce6frKtrVlRmMKW+OQk7Y/znPfkDO5v75ZgBoZvo+n0ZThagOl8BsZgPgHzMZhNQi3iJEqWUZxEcbKI4veP1cPj09X1zcA1WBvKO/uKzIoaxYuiKIQQnHPGGOe8quufrlDOee8HPuD8N8+f+wnZC5bTLGOsyDKKCcl3zNljy4lGPowRnEMIEYQpQc0HoS3BmOAN3MA1FIL3yUophBBEuDEwghtIMMYYEUzyPJdSXpYPVFWllDR7o5TWWisZHq21MYfpvWsLkaZpuJN0zrsWdcBa25lsjM6yrCi4Uspe4jz/lCylgHC9y3fe+3/LSkpCSFmW9W/aft/BhBBVVf0R2lM65TZli65Td8qUUrIllFKapueBR/kLjUXsz320uRUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/64f9e4ad3a34e4364ac94aff552c2473/e4706/HomeAssistant_08.avif 230w", "/en/static/64f9e4ad3a34e4364ac94aff552c2473/d1af7/HomeAssistant_08.avif 460w", "/en/static/64f9e4ad3a34e4364ac94aff552c2473/b6582/HomeAssistant_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/64f9e4ad3a34e4364ac94aff552c2473/a0b58/HomeAssistant_08.webp 230w", "/en/static/64f9e4ad3a34e4364ac94aff552c2473/bc10c/HomeAssistant_08.webp 460w", "/en/static/64f9e4ad3a34e4364ac94aff552c2473/87ca7/HomeAssistant_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/64f9e4ad3a34e4364ac94aff552c2473/81c8e/HomeAssistant_08.png 230w", "/en/static/64f9e4ad3a34e4364ac94aff552c2473/08a84/HomeAssistant_08.png 460w", "/en/static/64f9e4ad3a34e4364ac94aff552c2473/e9beb/HomeAssistant_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/64f9e4ad3a34e4364ac94aff552c2473/e9beb/HomeAssistant_08.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Type in your preferred login credentials and click to create the account:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd5ada92af7bea812a9506f4995ee2e9/e9beb/HomeAssistant_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABRklEQVQoz4WQzU6EMBSFeUYXxoczRp/ECISCC+O4nIUZFYSB/pcCbSnXMJPM6Dg/Jycnd9Ev594GrwhlYZSi9DGMnsL4OUsTlEYojRGKkzRGWYzSOEFzojSMkyhBL4u327v7q+ubgDO2NWOUUJp94GWJSdM0GGNC5sQEE0LZXpxzQmlVVQH8kvHw8G4W1BrJhOCCsaauCW6stXBMwbQRAPR9X6/Xy7z6LOuK67UydAA6TFg7xlir2wlg+qt9c99130VBG1zk+eqrWOVlQ0VrnJ3Ae78tOGzeTeM46k5b65RUSkophGDs1MKH8ND3TV3rtv3/aHfaSbgbbIm5VMo5O9tu7NzonPf+QrN1nmtjnZ8A/ATjNKff9EnVflfVdoXjsBk90YYNo7AgLCgP0s2DdFC3pmLyHHxwpeTcnf2tk7D3XmttL8E/cAWvwyAKg/cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd5ada92af7bea812a9506f4995ee2e9/e4706/HomeAssistant_09.avif 230w", "/en/static/fd5ada92af7bea812a9506f4995ee2e9/d1af7/HomeAssistant_09.avif 460w", "/en/static/fd5ada92af7bea812a9506f4995ee2e9/b6582/HomeAssistant_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd5ada92af7bea812a9506f4995ee2e9/a0b58/HomeAssistant_09.webp 230w", "/en/static/fd5ada92af7bea812a9506f4995ee2e9/bc10c/HomeAssistant_09.webp 460w", "/en/static/fd5ada92af7bea812a9506f4995ee2e9/87ca7/HomeAssistant_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd5ada92af7bea812a9506f4995ee2e9/81c8e/HomeAssistant_09.png 230w", "/en/static/fd5ada92af7bea812a9506f4995ee2e9/08a84/HomeAssistant_09.png 460w", "/en/static/fd5ada92af7bea812a9506f4995ee2e9/e9beb/HomeAssistant_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd5ada92af7bea812a9506f4995ee2e9/e9beb/HomeAssistant_09.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`And login to the Home Assistant web interface using your credentials:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5b471c3aa9c3f69702567ecca0eab68f/e9beb/HomeAssistant_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABaUlEQVQoz52Ny0rDQABFxw/wX4R27V7QtQvxUXfVNp3J1DRZ6JdUcOFKCiIKgivf+FqICEJFUKgtZppkHknMY9IICqJtRevhLO/hgum53OTUzEIR7h8enZ6dX1xeHRyfVDe2Vms71dr22ubu+vbeTwKXU0YdRp1WsxkHfp2I3dvH4ckVMFYemtDA+KdLfbS4a7u+IzxbeOI1tILk5qk1ml8emV3KzuuZnJaZ07I5LZOr9ArunOjB61zbsi6SZ0++xOl901w29DKClTKuwKKhLGrFRQ0qGla7BISYru8L1+2kqUw6aZqahGi6rkCIMIaKAguFhXxeKSkQY6iqXwWu4LGUlNIoitJ3CCG6rkMIMcYIIQQRLJUQQmoPQHAaxzFjrCv+tsZY7QdoEzMIAsaYlLLrWf0N4NhWFEW2bbfb7Y/zAWLPFcE7UsokSQaLGXUsy+Kc/yfmjEopwzDknBNCwjBsNBqGYfwlfgNogrbzCV6MSQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b471c3aa9c3f69702567ecca0eab68f/e4706/HomeAssistant_10.avif 230w", "/en/static/5b471c3aa9c3f69702567ecca0eab68f/d1af7/HomeAssistant_10.avif 460w", "/en/static/5b471c3aa9c3f69702567ecca0eab68f/b6582/HomeAssistant_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5b471c3aa9c3f69702567ecca0eab68f/a0b58/HomeAssistant_10.webp 230w", "/en/static/5b471c3aa9c3f69702567ecca0eab68f/bc10c/HomeAssistant_10.webp 460w", "/en/static/5b471c3aa9c3f69702567ecca0eab68f/87ca7/HomeAssistant_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b471c3aa9c3f69702567ecca0eab68f/81c8e/HomeAssistant_10.png 230w", "/en/static/5b471c3aa9c3f69702567ecca0eab68f/08a84/HomeAssistant_10.png 460w", "/en/static/5b471c3aa9c3f69702567ecca0eab68f/e9beb/HomeAssistant_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5b471c3aa9c3f69702567ecca0eab68f/e9beb/HomeAssistant_10.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Hit ctrl-c to stop Home Assistants. The config directory now contains a bunch of sample config files for you to edit.`}</li>
    </ol>
    <h3 {...{
      "id": "run-home-assistant-as-a-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#run-home-assistant-as-a-service",
        "aria-label": "run home assistant as a service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run Home Assistant as a Service`}</h3>
    <ol>
      <li parentName="ol">{`Assuming it works, exit the homeassistant user, change to the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/etc/systemd/system/`}</code>{` directory and create a service to start Home Assistant automatically.`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exit`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /etc/systemd/system/
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` homeassistant.service`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Create the following `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`systemd init`}</code>{` file:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c2eed236cdfe811d182378d2c0d262f3/e9beb/HomeAssistant_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABBklEQVQY0xXC2XKCMBQAUL6jD7XYQlgSyA3ZSEQRBzoSUVTsIj61//8PnZ453s/v436f5nma59vj8XG9DtPt9PV9ud6Ox9N+GN7HcX++uGkaxnM/jv+da7e76j5/em1Xb2tjrLAryXmW4jBJgyyPSRZF8RIhP4qXcfKa4gBFfhC+hKH/FiyeF0/bZu0dDl3v2mZXGcO1ZsYUSoNUYC1XGrjIhaScEylzpakQuZQ5QBonQe86r3dt77qmsZuNrtbKWmEMl5LalShLVpaFLlnBCTCsNShFdQlKASHRMOy9U19vaitFbq2wVgBNuMhZgSkkwFJK4yxDDFKABJMQE0QIwgQh5DvX/gGreEPhqBBftAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c2eed236cdfe811d182378d2c0d262f3/e4706/HomeAssistant_11.avif 230w", "/en/static/c2eed236cdfe811d182378d2c0d262f3/d1af7/HomeAssistant_11.avif 460w", "/en/static/c2eed236cdfe811d182378d2c0d262f3/b6582/HomeAssistant_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c2eed236cdfe811d182378d2c0d262f3/a0b58/HomeAssistant_11.webp 230w", "/en/static/c2eed236cdfe811d182378d2c0d262f3/bc10c/HomeAssistant_11.webp 460w", "/en/static/c2eed236cdfe811d182378d2c0d262f3/87ca7/HomeAssistant_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c2eed236cdfe811d182378d2c0d262f3/81c8e/HomeAssistant_11.png 230w", "/en/static/c2eed236cdfe811d182378d2c0d262f3/08a84/HomeAssistant_11.png 460w", "/en/static/c2eed236cdfe811d182378d2c0d262f3/e9beb/HomeAssistant_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c2eed236cdfe811d182378d2c0d262f3/e9beb/HomeAssistant_11.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`[Unit]
Description=Home Assistant
After=network.target mosquitto.service

[Service]
Type=simple
User=homeassistant
ExecStart=/opt/homeassistant/bin/hass -c /opt/homeassistant/config --log-file /opt/homeassistant/hass.log

[Install]
WantedBy=multi-user.target`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Exit the homeassistant user, copy the service file to the system, and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`update systemd`}</code>{` to run the service.`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` systemctl --system daemon-reload
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` systemctl `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`enable`}</span>{` homeassistant
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` systemctl start homeassistant`}</code></pre></div>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`If something goes wrong with the start command, check the logs:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` systemctl status homeassistant`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/48111beb0a67c415ba9b2feb0c85d1f8/e9beb/HomeAssistant_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABoklEQVQozx2RSY7bMBREdZkgiSfJkuWYEmVxJj9HkZHS6QBZNLJp9KLvvwzsA7yqh6rq9c/6+zWvv1JKkLPdtriucSkuLTYlow0LQadkfFCUDk2z67pj0+yb8+FUf6+2LW3bsm7RgjCGGcOUJoxjLiYuppkgzicuMKXjNF3P7b7vT5fL8dwe6mZXrVt8eck+cOuYhjlnu67BByXEpNTMGKZkkPKOp+vtdm7bw+UBn3b7L4fj18o66ZwAS0IUQo0zQeTZNs8I4x7j/vajwbhHQ9d1h/7aXC6nut7V9e5Uf6uGoRvHXmuqFfFeCXknBElFpCIItRj349Ah1E3z7YZahNpnyhGhrmn2FVjO2GgMUWq2VizZGkOVpmBljAaAeS9j1DFqramFxyjaMCHuI75WKQEAKxm8l96rdY0lO7DCeVWKD0GX4nK2pbjHHQuEqHMJ5slX2jDGJwCuNM3ZLQmck1oTbdjPNcQEpfic4QmbvFjn5bJYa6UBVn1+vr+/v318/Hv7W8CwGLVShNCRi7vz2gVjgYGh1nJjaIxaqtnAw3wmw3/10I+oQJ1rngAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/48111beb0a67c415ba9b2feb0c85d1f8/e4706/HomeAssistant_12.avif 230w", "/en/static/48111beb0a67c415ba9b2feb0c85d1f8/d1af7/HomeAssistant_12.avif 460w", "/en/static/48111beb0a67c415ba9b2feb0c85d1f8/b6582/HomeAssistant_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/48111beb0a67c415ba9b2feb0c85d1f8/a0b58/HomeAssistant_12.webp 230w", "/en/static/48111beb0a67c415ba9b2feb0c85d1f8/bc10c/HomeAssistant_12.webp 460w", "/en/static/48111beb0a67c415ba9b2feb0c85d1f8/87ca7/HomeAssistant_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/48111beb0a67c415ba9b2feb0c85d1f8/81c8e/HomeAssistant_12.png 230w", "/en/static/48111beb0a67c415ba9b2feb0c85d1f8/08a84/HomeAssistant_12.png 460w", "/en/static/48111beb0a67c415ba9b2feb0c85d1f8/e9beb/HomeAssistant_12.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/48111beb0a67c415ba9b2feb0c85d1f8/e9beb/HomeAssistant_12.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Finally, to make it easier to edit config files and try out code changes, give your regular user write permissions in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`homeassistant directory`}</code>{`. For this we need to assign both our regular user - in my case this is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nodeadmin`}</code>{` - and the homeassistant user to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`homeassistant group`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`groupadd`}</span>{` homeassistant
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`usermod`}</span>{` -a -G homeassistant nodeadmin
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`usermod`}</span>{` -a -G homeassistant homeassistant`}</code></pre></div>
    <p>{`Make sure that both users are inside the created group:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bae7ddb500078766f39cc9081b0192f1/e9beb/HomeAssistant_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "10.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAf0lEQVQI12MICfUPDQsICvLx9nb18XENCPAKCvTyC/KK9fOs93DLs7cutLfJtLFyM9bX0VPX1VU3NtY1MNDS09MwMNBkSEiKTUiMDg7yCQ31CwryDgryiYgIDA0PTI70i/ew9bK18DA3dDDW09LXMDTQ0tZRMzDQBGtWNzDUAgAL8B+OEKG9AgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bae7ddb500078766f39cc9081b0192f1/e4706/HomeAssistant_13.avif 230w", "/en/static/bae7ddb500078766f39cc9081b0192f1/d1af7/HomeAssistant_13.avif 460w", "/en/static/bae7ddb500078766f39cc9081b0192f1/b6582/HomeAssistant_13.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bae7ddb500078766f39cc9081b0192f1/a0b58/HomeAssistant_13.webp 230w", "/en/static/bae7ddb500078766f39cc9081b0192f1/bc10c/HomeAssistant_13.webp 460w", "/en/static/bae7ddb500078766f39cc9081b0192f1/87ca7/HomeAssistant_13.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bae7ddb500078766f39cc9081b0192f1/81c8e/HomeAssistant_13.png 230w", "/en/static/bae7ddb500078766f39cc9081b0192f1/08a84/HomeAssistant_13.png 460w", "/en/static/bae7ddb500078766f39cc9081b0192f1/e9beb/HomeAssistant_13.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bae7ddb500078766f39cc9081b0192f1/e9beb/HomeAssistant_13.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chgrp`}</span>{` -R homeassistant /opt/homeassistant
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` -R g+w /opt/homeassistant/`}</code></pre></div>
    <h2 {...{
      "id": "configuring-home-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-home-assistant",
        "aria-label": "configuring home assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring Home Assistant`}</h2>
    <p>{`We set up Home Assistant to be installed in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/homeassistant`}</code>{`. The configuration file can be found in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`config`}</code>{` subfolder. Let's open up the file to take a look at it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo nano /opt/homeassistant/config/configuration.yaml`}</code></pre></div>
    <h3 {...{
      "id": "adding-the-mqtt-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-the-mqtt-broker",
        "aria-label": "adding the mqtt broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the MQTT Broker`}</h3>
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/e9beb/HomeAssistant_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABEklEQVQY0xXGa0sCQRQA0P0dYbo+Zmfnce/M7JaEUYFC4qPVfY3rpoYh1AeFNKMk6LeHXw7HWSxTW4xsPpyno0UalWmUT4dpPJpMh1E0iJNxbmNbZHaW2iLLbBwn4zR7mpfJrIid6w7Rbde0qwYrQCqifimbVUrrlDZ8RnzWYoJIxUFxJigTnuc3CXGJ5xKv7vQHdze3EAYUaQ1JjbsXolGhfsOjrtI8vFJBiEGozFk052gTaEQuwXfGk+4k6W3eng/7981yvi7zdWlfVuX6dXn/0JHgA/hKC22kDhA1gJJoEIAL3nL6PVAhWayS37/j8bD9/tj+7Hef+93p9PXY76LiJkTUQhkJyCQwiQwUV4FGg/8rq0enodQRrAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/e4706/HomeAssistant_14.avif 230w", "/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/d1af7/HomeAssistant_14.avif 460w", "/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/b6582/HomeAssistant_14.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/a0b58/HomeAssistant_14.webp 230w", "/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/bc10c/HomeAssistant_14.webp 460w", "/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/87ca7/HomeAssistant_14.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/81c8e/HomeAssistant_14.png 230w", "/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/08a84/HomeAssistant_14.png 460w", "/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/e9beb/HomeAssistant_14.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ef0ee1d89a5a8d275e5e7781a2fd1c64/e9beb/HomeAssistant_14.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The first thing we should do, is adding our Mosquitto server (192.168.2.111) as MQTT broker. We can do this by adding the following lines at the end of the document:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# MQTT Communication

mqtt:
  broker: 192.168.2.111
  port: 1883
  user: debian
  password: mysecretpassword`}</code></pre></div>
    <PrimaryBox2 mdxType="PrimaryBox2" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`To verify that we did not mess up the configuration we can go to the `}<strong parentName="p">{`Configuration Tab`}</strong>{` in Home Assistant:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ef539c7c97ac8df8aed60e92f5615220/e9beb/HomeAssistant_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABUElEQVQoz5XOS0sCURTA8Vv7PkWfoU/QrsciAoNEdxX4mrlz1bnlI6IvoVBtJcx9u3aFQY+FklQggeWMzos7c6/eIecGRRsT0sNvcRbnDwcUj45xrlCp1ppPrcdG86p+ly9XcKlyUK4UTqrF04sxh2c1tXS+FNpdXN4AnFHqEqvfI7bZ84aXjbeFUB6sKHPrGbCGwOpk85u5+a0CaBO/7fBXh394vsFFq6PvoFw4kY4mUDQOozEYSaYjqcyYcBxtxxF4t6nm8i77JH4wEKKjG/l9nFagCiU1GUexvQyUsghlkfIXoC5h1GUuEUEghDAMQ8VYhhAqiiTDlCT97BOBAedD33cpC35jjPH3/f8DrHab6jrV9JFli9HIMM0ZYvv6hj2/9O4fnPqtMC3DcbCqThuzvkG6GreswPNmfpt4nssYGw4pY9z3dU3DGMuyPE38BanGWm1/Z+ZhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef539c7c97ac8df8aed60e92f5615220/e4706/HomeAssistant_15.avif 230w", "/en/static/ef539c7c97ac8df8aed60e92f5615220/d1af7/HomeAssistant_15.avif 460w", "/en/static/ef539c7c97ac8df8aed60e92f5615220/b6582/HomeAssistant_15.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ef539c7c97ac8df8aed60e92f5615220/a0b58/HomeAssistant_15.webp 230w", "/en/static/ef539c7c97ac8df8aed60e92f5615220/bc10c/HomeAssistant_15.webp 460w", "/en/static/ef539c7c97ac8df8aed60e92f5615220/87ca7/HomeAssistant_15.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef539c7c97ac8df8aed60e92f5615220/81c8e/HomeAssistant_15.png 230w", "/en/static/ef539c7c97ac8df8aed60e92f5615220/08a84/HomeAssistant_15.png 460w", "/en/static/ef539c7c97ac8df8aed60e92f5615220/e9beb/HomeAssistant_15.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ef539c7c97ac8df8aed60e92f5615220/e9beb/HomeAssistant_15.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the `}<strong parentName="p">{`General`}</strong>{` section:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/10af770c0cc955aaa8f06dffe283556a/e9beb/HomeAssistant_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABOklEQVQoz42RW1PCMBCF+/9/nZfRUl4EappLTdOS++ai0wYEBJVvdvKQydlzdlOFEMZx3G437XtLKEUIYYzbdoc65JyP4QRcUQUArfUwDJzzcZyEENM0n0IIbYxdMMZorb33V+LFmRDKKKWE9Ix1XccYI4Rg3GGMCSEIIUqptfaHfwUA3ntjZhe9YK0tl+cAQLjk4Oyc6/ueEDJnHkeA8PkfpVdxdstcprT0d+CcO8VWSkmpAkBKKcaYFuLvXMzMKGWMSSnNEefcXbEBvLU255xyTmf8Ic45l3+eF0b7fq9UXAT5+0WpWxxnDsFr7YUwH9xy7gbhhuFUnDut3RVlbVVIyby80qdnudkGTDzqoMOHwhh2bXzblKC3Zk7J1iv+8LhfNapZT3Wtmkat1rJpZLPWr3Xc3BCXhX8BnQHvLYyLz4EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/10af770c0cc955aaa8f06dffe283556a/e4706/HomeAssistant_16.avif 230w", "/en/static/10af770c0cc955aaa8f06dffe283556a/d1af7/HomeAssistant_16.avif 460w", "/en/static/10af770c0cc955aaa8f06dffe283556a/b6582/HomeAssistant_16.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/10af770c0cc955aaa8f06dffe283556a/a0b58/HomeAssistant_16.webp 230w", "/en/static/10af770c0cc955aaa8f06dffe283556a/bc10c/HomeAssistant_16.webp 460w", "/en/static/10af770c0cc955aaa8f06dffe283556a/87ca7/HomeAssistant_16.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/10af770c0cc955aaa8f06dffe283556a/81c8e/HomeAssistant_16.png 230w", "/en/static/10af770c0cc955aaa8f06dffe283556a/08a84/HomeAssistant_16.png 460w", "/en/static/10af770c0cc955aaa8f06dffe283556a/e9beb/HomeAssistant_16.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/10af770c0cc955aaa8f06dffe283556a/e9beb/HomeAssistant_16.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Check Config`}</strong>{` and - `}<strong parentName="p">{`when it passes`}</strong>{` - click on `}<strong parentName="p">{`Restart Server`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8cefc2732e2dc7fb5c916cda1df331e9/e9beb/HomeAssistant_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.69565217391303%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACT0lEQVQ4y31UTY/TMBDtv+PGb0PiZyAkxBFxQBzgDvftNk0aJ3UTj8d2/DETo8Qlu7DdPjnWU5TneZ6P7ABASjkMwziOSqlCAGAYBkSklGKMaUV8gZ2Usjoe66Zp21aI5Wmapu+60+kkpfTeT9PknJum6YZ4GIbz+bzusu97KeXlchnHERG11koprfU4jlrrG2JrjLPWrmc7V6gjomK1kLLfElt7rOrH/WN1qPquq6rqYb+/DJe8YM63MM/zVRxCWB0uJo0xiKgAjDXxb5JK5PJ1ecfMW2TTNI0QXd/1dVODUtZYg7gsYzXo66GgAXRJxHaFHRHNzETEK+Z5sUrznDJR5jTzPdvMvL3LOXNzil++Ql2d8CKUHKzeBHfFKwmfPus3b92378r7UUrn3L3IxfBmm+eZYlzcL7HmK/kXzBxCWMQ+hJgirSi1TUwpRn8XV7E1pjoc9w/7w+EghKiO1eP+QZ7l62V+ZjvGYIyFpSRorcUV1tqtq8q+hkohhMXZVmdjUIhW9r0QrTidOiG6thWt6IRADQb1ulCDsgYNakTzJE5ENjJ6mhLZQBhIewqJXeKJ80TZUeY5B84Tr3y1HYrYhyht6kxET2pKnUkdRu3CGYMA34I/gQcXevQCluV8WO5QEuacQzW61dnqEyZniBKvWWdKMyWiJ/4sBXFnEGspz6DqY70/HI51fRmG/1rq9WznbH/8tL9+T8zLMC/j7KwxGjUAIOLdDstZv3tvPnxMOXulytQx8/YzuDvP3k8A3rngnAeIKZX7bAgvsHXYH6HN9evgduwiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8cefc2732e2dc7fb5c916cda1df331e9/e4706/HomeAssistant_17.avif 230w", "/en/static/8cefc2732e2dc7fb5c916cda1df331e9/d1af7/HomeAssistant_17.avif 460w", "/en/static/8cefc2732e2dc7fb5c916cda1df331e9/b6582/HomeAssistant_17.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8cefc2732e2dc7fb5c916cda1df331e9/a0b58/HomeAssistant_17.webp 230w", "/en/static/8cefc2732e2dc7fb5c916cda1df331e9/bc10c/HomeAssistant_17.webp 460w", "/en/static/8cefc2732e2dc7fb5c916cda1df331e9/87ca7/HomeAssistant_17.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8cefc2732e2dc7fb5c916cda1df331e9/81c8e/HomeAssistant_17.png 230w", "/en/static/8cefc2732e2dc7fb5c916cda1df331e9/08a84/HomeAssistant_17.png 460w", "/en/static/8cefc2732e2dc7fb5c916cda1df331e9/e9beb/HomeAssistant_17.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8cefc2732e2dc7fb5c916cda1df331e9/e9beb/HomeAssistant_17.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "the-home-assistant-lovelace-ui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-home-assistant-lovelace-ui",
        "aria-label": "the home assistant lovelace ui permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Home Assistant Lovelace UI`}</h2>
    <p>{`Now we can start with adding components to the Home Assistant UI - called `}<strong parentName="p">{`Lovelace`}</strong>{`. Adding Buttons and switches to interact with your camera's API through an MQTT interface is `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"
      }}>{`described in detail for the INSTAR MQTT Broker here`}</a>{`. Note that the INSTAR MQTT Broker is only included in `}<em parentName="p">{`INSTAR Full HD cameras`}</em>{`. If you are using a HD (720p) or VGA camera you can still use MQTT to connect your camera. Install the MQTT broker on your Linux system as detailed above and use `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/"
      }}>{`Node-RED as an Interface between your camera and the MQTT Layer`}</a>{`. Once you have this set up you can connect your cameras to Home Assistant using the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"
      }}>{`INSTAR MQTT Broker Tutorial`}</a>{`.`}</p>
    <p>{`The article about the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"
      }}>{`INSTAR MQTT Broker`}</a>{` already tells you everything you need to know on how to add your camera's function to the Home Assistant UI. But what is missing is the `}<strong parentName="p">{`Live Video`}</strong>{`.`}</p>
    <h3 {...{
      "id": "adding-your-cameras-live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-your-cameras-live-video",
        "aria-label": "adding your cameras live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your camera's Live Video`}</h3>
    <p>{`Home Assistant comes with a `}<strong parentName="p">{`Camera Module`}</strong>{` that we can use to add our camera's Live Video. Lets start by accessing the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code>{`. When you followed the steps above you will find this file in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/homeassistant/config/configuration.yaml`}</code>{`. When you followed the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"
      }}>{`INSTAR MQTT Broker Tutorial`}</a>{` and installed Home Assistant on a Raspberry Pi, it will be inside your default user home directory: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/home/pi/.homeassistant/configuration.yaml`}</code>{`. Open the configuration file and add the line:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`camera`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}>{`!include`}</span>{` cameras.yaml`}</code></pre></div>
    <p>{`Now create the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras.yaml`}</code>{` file next to the configuration file and add the following lines:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` generic
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'IN-8015FHD'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`still_image_url`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//192.168.2.165/tmpfs/snap.jpg
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`stream_source`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'rtsp://192.168.2.165:554/11'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`verify_ssl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`authentication`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'basic'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`username`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'admin'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`password`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar'`}</span></code></pre></div>
    <p>{`In case you need to restrict the bandwidth this live stream is requiring, use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`auto.jpg`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`auto2.jpg`}</code>{` instead of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`snap.jpg`}</code>{` in the `}<strong parentName="p">{`still_image_url`}</strong>{` above. Also the RTSP `}<strong parentName="p">{`stream_source`}</strong>{` can use a smaller live stream by replacing the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/11`}</code>{` with a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/12`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/13`}</code>{`. Alternatively, you can access your camera's MJPEG stream with the following configuration:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mjpeg
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'IN-9010FHD'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`mjpeg_url`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//192.168.2.117/mjpegstream.cgi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`chn=11
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`verify_ssl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`authentication`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'basic'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`username`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'admin'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`password`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar'`}</span></code></pre></div>
    <p>{`And again, to reduce the bandwidth requirements of this live stream use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chn=12`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chn=13`}</code>{` instead of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chn=11`}</code>{`.`}</p>
    <p>{`This will add a local INSTAR HD or Full HD camera with the IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.165`}</code>{` on the default `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`HTTP Port`}</a>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`80`}</code>{` and with the default `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`RTSP Port`}</a>{` 554. The camera login is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin/instar`}</code>{` in this example - but note: you do not need to use the Administrator account to access the live video.`}</p>
    <p>{`Now reload Home Assistant to be able to see the changes you just made. Once the UI is back up, click on `}<strong parentName="p">{`Configure UI`}</strong>{` in the top right:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "867px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8190b6937dd2624f435db5a00bdf1f7f/264eb/HomeAssistant_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABX0lEQVQY003GW0vCYACA4f2hILqJiCTNA0Uq5nGmTN1sHuaaO7t9m06dSnkToXgKEtOCLMkrsZAu1G77SWHdBA8vL7S1vefDQXGw1PuLcn8BOnOl9w66G3+j9P65+1DbMzYu4TGBQVhoZ9ccuqxevXzXHte1py99tNaHG5XRuvywqgw3LQ2Wv1bl4Vq//8QcEactEDa5IYsdgYkCezuWGmOp+ZrvvIHWBLQm+e5U7U7ro3llMMu1p2JryjcnXGOSvXm2e3GT0WWy+CCHG7V5EAeW9KUZmBL8pOAlOHea9ZACzKmIrGOFa0yrR9RaQCye0copkbPijDFGGRACMpi9Fte5P0VGaAHl5RgnhSkBJnmYEoOsguRKUVCNgGpQLHloxZ4Rj5Oc9YIxRsmDUALywElXEEWITCYn03ktm9cyQMVFGeNlTFAxSUPlUljU/DRwEvxJgrHGqSOUPERS+zD2A5lQrDhN93H4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8190b6937dd2624f435db5a00bdf1f7f/e4706/HomeAssistant_18.avif 230w", "/en/static/8190b6937dd2624f435db5a00bdf1f7f/d1af7/HomeAssistant_18.avif 460w", "/en/static/8190b6937dd2624f435db5a00bdf1f7f/0d127/HomeAssistant_18.avif 867w"],
              "sizes": "(max-width: 867px) 100vw, 867px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8190b6937dd2624f435db5a00bdf1f7f/a0b58/HomeAssistant_18.webp 230w", "/en/static/8190b6937dd2624f435db5a00bdf1f7f/bc10c/HomeAssistant_18.webp 460w", "/en/static/8190b6937dd2624f435db5a00bdf1f7f/415aa/HomeAssistant_18.webp 867w"],
              "sizes": "(max-width: 867px) 100vw, 867px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8190b6937dd2624f435db5a00bdf1f7f/81c8e/HomeAssistant_18.png 230w", "/en/static/8190b6937dd2624f435db5a00bdf1f7f/08a84/HomeAssistant_18.png 460w", "/en/static/8190b6937dd2624f435db5a00bdf1f7f/264eb/HomeAssistant_18.png 867w"],
              "sizes": "(max-width: 867px) 100vw, 867px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8190b6937dd2624f435db5a00bdf1f7f/264eb/HomeAssistant_18.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You might want to add a new Tab for your camera by clicking on the small `}<strong parentName="p">{`+`}</strong>{` icon next to your existing tabs. Then click on the big `}<strong parentName="p">{`+ Button`}</strong>{` to add a new card.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "869px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/85362b4b4a06c751ae901736c796359e/2b2c6/HomeAssistant_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABS0lEQVQY0x3CW0+CUAAAYP5SD9XWmpc8AzIVFYlzuCkcLsrlCBiXOJiJLTc316v/oOf+YatvH3N13b3vi7zhjXEo4BBA/CAv/psAmj1o9uS/AFpdaHWQ1ZMXrIpRsGEVzNy2Bu0h4nRvaPpP8xWve5zqcJr7qHsAYYAwrzms4nQgFkVdFvWuYgPNHVgBQBbDT2wwszjDk/zNdJko61IO8+cgUxM6CzIj20XNya0/paT23BgbS84mUDZFwxdWOTMSMS+ZY5fAKFNTCuPqmRRSVEhxpZUN3p2c5ux+fDmHM6qPQt5IhH7ftC9Lmlx+mBaQ2KkGfbJIS5xT86XS4gKRHK1LZVMbZWPWx3l9VIpmlm5HUcmvMs6OgUU6RsBIij9RrEVEolea7vbJ7j2ib15J7YLaxdau9pge9HIvp7UQ5v3lhnXiHibtuX+H8C9flor3HRiUHAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85362b4b4a06c751ae901736c796359e/e4706/HomeAssistant_19.avif 230w", "/en/static/85362b4b4a06c751ae901736c796359e/d1af7/HomeAssistant_19.avif 460w", "/en/static/85362b4b4a06c751ae901736c796359e/53e78/HomeAssistant_19.avif 869w"],
              "sizes": "(max-width: 869px) 100vw, 869px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/85362b4b4a06c751ae901736c796359e/a0b58/HomeAssistant_19.webp 230w", "/en/static/85362b4b4a06c751ae901736c796359e/bc10c/HomeAssistant_19.webp 460w", "/en/static/85362b4b4a06c751ae901736c796359e/c3694/HomeAssistant_19.webp 869w"],
              "sizes": "(max-width: 869px) 100vw, 869px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85362b4b4a06c751ae901736c796359e/81c8e/HomeAssistant_19.png 230w", "/en/static/85362b4b4a06c751ae901736c796359e/08a84/HomeAssistant_19.png 460w", "/en/static/85362b4b4a06c751ae901736c796359e/2b2c6/HomeAssistant_19.png 869w"],
              "sizes": "(max-width: 869px) 100vw, 869px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/85362b4b4a06c751ae901736c796359e/2b2c6/HomeAssistant_19.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we have to add a new Entity and select the camera that we just added - in the case above this was `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`camera.in_8015fhd`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "872px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9ac25472f29d3c3a3f8916ec0a796102/65654/HomeAssistant_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABBklEQVQY003L326CMByGYe7CLMoymMx2QFvaX2sLUgpU8U+2ZM6ou/8bWRg7MHkO3oPvC2bzeBZGT1ESJmixXMU4e37DLyiNUBomKMJ5ktEIZ4sEhQmev64eBShTwvRQ9bLyTDlENaYGs9E7KzNeTdKiwswg+g+zMk55QIX1x+v2dNt//NTdJwFHZUdlOyEwojA2e1Aov8xVQMFuT1e3+2qHsz9c2uGci4aAI9CQMcbO/qQPqOyWGQQo10x1hfayPnGzm9YEHDcDbA5E9SDsvai/ub2v/U31F24vxWYNLp7OVLbc7GR9FOV+ehJwotrD5lBoT0VTikYLW5uh1lvNrRGWyy5OxS/EXUp4uJ8AJwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ac25472f29d3c3a3f8916ec0a796102/e4706/HomeAssistant_20.avif 230w", "/en/static/9ac25472f29d3c3a3f8916ec0a796102/d1af7/HomeAssistant_20.avif 460w", "/en/static/9ac25472f29d3c3a3f8916ec0a796102/749ce/HomeAssistant_20.avif 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9ac25472f29d3c3a3f8916ec0a796102/a0b58/HomeAssistant_20.webp 230w", "/en/static/9ac25472f29d3c3a3f8916ec0a796102/bc10c/HomeAssistant_20.webp 460w", "/en/static/9ac25472f29d3c3a3f8916ec0a796102/a8a2c/HomeAssistant_20.webp 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ac25472f29d3c3a3f8916ec0a796102/81c8e/HomeAssistant_20.png 230w", "/en/static/9ac25472f29d3c3a3f8916ec0a796102/08a84/HomeAssistant_20.png 460w", "/en/static/9ac25472f29d3c3a3f8916ec0a796102/65654/HomeAssistant_20.png 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9ac25472f29d3c3a3f8916ec0a796102/65654/HomeAssistant_20.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "870px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4cc8706e811b8f5e60aa5c87aef293f1/3f3b9/HomeAssistant_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB+UlEQVQ4y5WSXW/aMBSG8xsm7aJIrAPaQkLiOLYTJyRxvoOBkFExEaClVaXuQxvV1N2s2s3++kRCUTdpwKRH58qPz/F7LLx6XTt506yftRsd+fRCqjUvas3zg7REuX7WFk7qrXpLOm0rb9tKowMaHbWsBziXUUMEAjLDNCt4vhhNrlk8UbCvkuAggPgKZoJKmMWGup2abICtWMHeMQDiy5gJuOxMeqmCPY1GyIyPQdWDjaxbER8vnGBsugNVDzQaHcNW1mgYjYqQT73k0nD4f3RGTMA2j7IrO8jc6J2XTFR9E8Z+tm9GTNAMP+TTruaKqi1BBxBvDwpmz1cEMnIFEVg+n0XDWZLNe0EOaayZyb+g7hDSCJCgHNsVJLXX8zPqct3uIyuBNNoDMmNohNAIVT3oaqXsJZe6nUIjPLiq6gDpbQ5vZBFYCvZkxKrtd6EtQluCtqy5f7H7IZqZAOJvZWiEVYYajeNsxSd3aX4bDJYvuPL5EtK4yhkQfzu2CKxqPQpmyOpff/hxv/518/lnVnzN5+sdo9kXZKV7ZE+j6c3Hx9Wnp+X903T1OC7W+fwhnz9MFt/e337fyVXtas4fMqSRx+fBYOHzOesXLH2mX3j9AhqRgr3NFzaT6glCB5jVzBVSmZYE7S50XiJBp0yLqbpPnCEyE4X4vwED+vDf7JOrtAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4cc8706e811b8f5e60aa5c87aef293f1/e4706/HomeAssistant_21.avif 230w", "/en/static/4cc8706e811b8f5e60aa5c87aef293f1/d1af7/HomeAssistant_21.avif 460w", "/en/static/4cc8706e811b8f5e60aa5c87aef293f1/36684/HomeAssistant_21.avif 870w"],
              "sizes": "(max-width: 870px) 100vw, 870px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4cc8706e811b8f5e60aa5c87aef293f1/a0b58/HomeAssistant_21.webp 230w", "/en/static/4cc8706e811b8f5e60aa5c87aef293f1/bc10c/HomeAssistant_21.webp 460w", "/en/static/4cc8706e811b8f5e60aa5c87aef293f1/bf818/HomeAssistant_21.webp 870w"],
              "sizes": "(max-width: 870px) 100vw, 870px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4cc8706e811b8f5e60aa5c87aef293f1/81c8e/HomeAssistant_21.png 230w", "/en/static/4cc8706e811b8f5e60aa5c87aef293f1/08a84/HomeAssistant_21.png 460w", "/en/static/4cc8706e811b8f5e60aa5c87aef293f1/3f3b9/HomeAssistant_21.png 870w"],
              "sizes": "(max-width: 870px) 100vw, 870px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4cc8706e811b8f5e60aa5c87aef293f1/3f3b9/HomeAssistant_21.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save your setting and quit the UI configuration mode. You should see a small tile with a snapshot from your camera. Click on it to be directed to the live video:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "868px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ee1c1b4ab74cf498015d9e110118bea6/748b0/HomeAssistant_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADlUlEQVQoz2XRzU/bdhwGcN936QtJHKdJ7LzZTvBLHPtnO07i+CUhbyRRSmjDywYLYVsh3aFTGJqgG4VWHQNaoLRdCysFqmoBKk1VO/a+HSet6qb1vMPUw/ZXTNHUXSZ99By+0qPn8IVOWmA/BYBeFvWiqBcjauH/+GQvnyz859WxAFlsToKNimZFNEqiURb1MtBLQC+JRgnoxU5qJSFZ7ND+VXqlCDlR3EOyIZCgJZUSk2xUZ2SNiWqsYoRjJtB6eLWHVUxGMeioTslaSEySQCWFBCkkIBQjvATjpziCBSQLCAaQrEgwgq+bC1A8zgCSk4IROcjJRFgKsMBLC1h3BAtxSCDUWeajqbhRzPTWUvl+PprmJBPEcrKalxI5IZbhRJMVNJpX6YjKCBoLdBZorKC7CQby+UOhAGmq6UKhks+Uhsbf7hsdmZr9oDU99ea7zcn3LtRP13QpTng9uNMliYBjGT+KqmoiQIUhxIF57XZNVE6XKvl09vrWZ1/8+NP2zubg8FDr4szc5Q8nztYyibgXsQVsXVE5wgb9qPVET0rD6TBkd6Bu2JIEcqVcGRoYvNdu7x8d3dnbbc3OXF2/tn348OPri9mU4bHbCASmaCKAOb0InOlJ+bvpThl1wDLDlQvF5rl3Vvd2bu5/fvXurdHzb80tX9l9vH9jbzObTntgC+FEgkGf227xuxy5bMZDBCE7gmJOO0eQ/f212fm55a2tB0df3Wk/XFhd2n18uP/tl+v3NjMpw4fAhNvRTeHIyWO4x60osgVxQHbEjTkdLI6PTzQXVtcOvv7h95d///LHn09+fvbNry+ev/zr/qNDQ42jsMXvdlA0gSJWH+YM4QHrqVOdMupyBDGsNTP7afvgu2e/ff/8xd32o8WbtxdW1j5Zv9VojNNBIhTw8hytKEASuXCYYlkGdrohm93lgC1Oa5eZSA4MDIzWx4ZGR3K9+Ww+11c7U5+caE63hhsjupmMxSU5KohiRAAcxzHHLbbOqwI+bzjMlqvV4cZYfXKyOT3dWrh0cXlpfuPGtZ3d5e3tyakL1YEzqR4znpDlKBAlgWXpYxYr5EL9MTU5ONp4//KV2eXFj1aW5tdWL62uza9vrNxv3z54uvHgcOz8uTfG631n+7O9WcNIJtSYJIITVhhyY3iQDnOSImuaYhgx09RyObNYTlf6q2PNar1ZrL3Ox+NcVKF4HqcoH0liPr8L87x2vOsfomoZjy3SBtYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee1c1b4ab74cf498015d9e110118bea6/e4706/HomeAssistant_22.avif 230w", "/en/static/ee1c1b4ab74cf498015d9e110118bea6/d1af7/HomeAssistant_22.avif 460w", "/en/static/ee1c1b4ab74cf498015d9e110118bea6/67bb0/HomeAssistant_22.avif 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ee1c1b4ab74cf498015d9e110118bea6/a0b58/HomeAssistant_22.webp 230w", "/en/static/ee1c1b4ab74cf498015d9e110118bea6/bc10c/HomeAssistant_22.webp 460w", "/en/static/ee1c1b4ab74cf498015d9e110118bea6/1ae05/HomeAssistant_22.webp 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee1c1b4ab74cf498015d9e110118bea6/81c8e/HomeAssistant_22.png 230w", "/en/static/ee1c1b4ab74cf498015d9e110118bea6/08a84/HomeAssistant_22.png 460w", "/en/static/ee1c1b4ab74cf498015d9e110118bea6/748b0/HomeAssistant_22.png 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ee1c1b4ab74cf498015d9e110118bea6/748b0/HomeAssistant_22.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "using-home-assistant-as-alarmserver-for-your-instar-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-home-assistant-as-alarmserver-for-your-instar-camera",
        "aria-label": "using home assistant as alarmserver for your instar camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using Home Assistant as Alarmserver for your INSTAR Camera`}</h3>
    <p>{`All INSTAR Full HD cameras have a function to send a POST request to a HTTP Webhook when an alarm is triggered (HD cameras currently only support GET requests that are not supported by Home Assistant - though you will be able to use Node-RED to receive a GET request from your HD camera and trigger a POST request to Home Assistant).`}</p>
    <p>{`When such a post request is received by Home Assistant such an event can be used as a trigger for your automation to control your INSTAR Full HD camera.`}</p>
    <p><strong parentName="p">{`For details on how to add camera controls for your INSTAR FUll HD camera please follow our `}<a parentName="strong" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"
        }}>{`INSTAR MQTT Broker Tutorial`}</a></strong></p>
    <p>{`To set up a Webhook that can be used by your camera first open the Lovelace UI and go to `}<strong parentName="p">{`Configuration`}</strong>{` and open the `}<strong parentName="p">{`Automation`}</strong>{` tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "918px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/e0885/HomeAssistant_23.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABg0lEQVQoz5WSy04iQRSG+zVniTtN3CgrF7NxXmBWLn0gExMThoamEbppAYWxaepyLnXBVA1kvEDUk39RldSX/z/nVELMv6+uf7ROzy4u2xe/Tts/W8fnRyft1vHnSoRU46LqdLNuP7/rpH/SrJNmaT/P78d7NRiOetmw28u7vTx5fFoQsfcb573zfvOFCo+dd84nz/VKKq00SKWf61UjJBITMRLtFSBqDSoqWSz/Kg1ADEhrIRCJtjBTODIb81rEjEgQldSrJsDxIqTSGgLATBxh4o+p2ZgtjNEzCoVU9aoBQGaDwdXsa9gz7+AdSTEMGmOtdXZb7qtwiC0kYLCNmUNvxtjPYMB/FylV3TREFEx9qO85N2sR5szsD+78LawBNSCwEVKt457jVuyhH8LWbmEdZ+s2GyrKpj9YD++pnNCkcg9TN52912xuJxVlObABwKSaL8tqlo/LRSeF4UiPChyXWJQB/qjpzBaT//DDfNkfjG5u76aPT1pD/FHmcMNxYLvYL4TBQoeemZN5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/e4706/HomeAssistant_23.avif 230w", "/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/d1af7/HomeAssistant_23.avif 460w", "/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/6d4a2/HomeAssistant_23.avif 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/a0b58/HomeAssistant_23.webp 230w", "/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/bc10c/HomeAssistant_23.webp 460w", "/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/56bc7/HomeAssistant_23.webp 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/81c8e/HomeAssistant_23.png 230w", "/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/08a84/HomeAssistant_23.png 460w", "/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/e0885/HomeAssistant_23.png 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0cb0723dfedb094aab9fdc1cbf45aa9b/e0885/HomeAssistant_23.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the `}<strong parentName="p">{`+`}</strong>{` icon to add a new automation and select `}<strong parentName="p">{`Webhook`}</strong>{` as the automation trigger - the name you choose here for the Webhook will be used to name the resulting URL e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqtt_test`}</code>{` (better don't use spaces or special characters):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "914px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/167aa284076b48c89f57249f68193c65/076ca/HomeAssistant_24.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABaElEQVQoz4WSu07DMBSG85hIMCDBgFTRARiBiYmR5wG1qFBAiAGJZ0CJL0ns48uxj5MgmhIKovTXN9iyPx3bx5nzeHF5tbU7Gh2fjY5PD0/Odw+OtvfGO/vj7b0NZGDsy+vbZPYwmz9P7x5v759ubufX0/vJbD69e/yfjHGRUtN1XSRSYMBYsE5pwBC7TcmElNZ56zwYWym9pNZlrbSx/dI6MllWzqPH4DEgBiKilJqmadeHKPX7My7kICsNGkxVK+99orWJMS5lDMuRsa6q1eKkuJGl3E8wRGNdwSUYF0LENfTOQOYx9LLSUHDBhMwLXnBZMMG4zJkouGSizJnQYH75q7J5z1nBRM4EFyUXFRNSlLUGq43V8NlC54PzP+XhzgBWa1NriIsnp5Q+oQUpRUqRKBIN9bPVY1S1whC6rus79efHaNs2En0/2CDXChBD2zaL/N3krm0pJdfLGsx3n8EgYrMp9FX5AyoMAayqKyHBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/167aa284076b48c89f57249f68193c65/e4706/HomeAssistant_24.avif 230w", "/en/static/167aa284076b48c89f57249f68193c65/d1af7/HomeAssistant_24.avif 460w", "/en/static/167aa284076b48c89f57249f68193c65/6ab89/HomeAssistant_24.avif 914w"],
              "sizes": "(max-width: 914px) 100vw, 914px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/167aa284076b48c89f57249f68193c65/a0b58/HomeAssistant_24.webp 230w", "/en/static/167aa284076b48c89f57249f68193c65/bc10c/HomeAssistant_24.webp 460w", "/en/static/167aa284076b48c89f57249f68193c65/38f11/HomeAssistant_24.webp 914w"],
              "sizes": "(max-width: 914px) 100vw, 914px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/167aa284076b48c89f57249f68193c65/81c8e/HomeAssistant_24.png 230w", "/en/static/167aa284076b48c89f57249f68193c65/08a84/HomeAssistant_24.png 460w", "/en/static/167aa284076b48c89f57249f68193c65/076ca/HomeAssistant_24.png 914w"],
              "sizes": "(max-width: 914px) 100vw, 914px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/167aa284076b48c89f57249f68193c65/076ca/HomeAssistant_24.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we need to define an action that we want to trigger. For this we are going to use a `}<strong parentName="p">{`MQTT Publish`}</strong>{` service we created in the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/#automations"
      }}>{`INSTAR MQTT Broker`}</a>{`. This service requires a MQTT topic (`}<em parentName="p">{`Target`}</em>{`) and a MQTT message payload (`}<em parentName="p">{`Message`}</em>{`) to be passed down:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"message"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"1\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"target"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/000389888811/features/ptz/preset"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "918px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d304776c49893a7dd761ec5e68f033c3/e0885/HomeAssistant_25.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB7UlEQVQ4y4WS22oUQRCG5ym9iSBIBDdg4oKiRERfRHwKL/RihcRNdk3inXgpErKHmenzobq65yA9nd2dbBL9+Wiqm/6nqqsm+/R59ODR3mD45uDVu2cv3w5fv3/6/HBnd//hk4P72Nndf7z3YjA8zH7/uTw6+T6eXoyOTr6Mjo9Pz07Pfown59tMz79NNoynF+PJeaa1adu2aVuHHhyGqmraazX9dX3aU8aFtOCUNvNlsciL2Xx5tSG/mi9n83y2WDIutLHGwhqlTSaVduiNhbwgeUnzkiwLkhebdZGXeUmZkOCwj7EQM6MPyZzuESZKwgjlKSgpI4wXhBkL2+YUWXAlYUVJuxwK0Ud8QO8j3dat2JgtuGT23T3XKYSA6IVQPFbrqqoKPbnbZsJ4ah6AswkL1q7im2ybKZdC6nVV/2XbTChPraJcUCYYlymgPMb/MnOhhFBSagBX11V8Z7VRWIH+VsMceqWNEKq581fqqWma9DQLkGlj05ek0lJqa8EYa8Hd56zrGtfmvKToQ9x0tusJo6/vUjezCtEbQGV6mQGc1BoRwTnvQ9PTVn6HvgrYVjYrusxp7lxIpTRlnHFhIRZilHYOb1betpUfXfqPvyArCEMfLLjoizLGGK2N0joi43EfIXUb4MNPO/gq/gJReORH+Uhr6AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d304776c49893a7dd761ec5e68f033c3/e4706/HomeAssistant_25.avif 230w", "/en/static/d304776c49893a7dd761ec5e68f033c3/d1af7/HomeAssistant_25.avif 460w", "/en/static/d304776c49893a7dd761ec5e68f033c3/6d4a2/HomeAssistant_25.avif 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d304776c49893a7dd761ec5e68f033c3/a0b58/HomeAssistant_25.webp 230w", "/en/static/d304776c49893a7dd761ec5e68f033c3/bc10c/HomeAssistant_25.webp 460w", "/en/static/d304776c49893a7dd761ec5e68f033c3/56bc7/HomeAssistant_25.webp 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d304776c49893a7dd761ec5e68f033c3/81c8e/HomeAssistant_25.png 230w", "/en/static/d304776c49893a7dd761ec5e68f033c3/08a84/HomeAssistant_25.png 460w", "/en/static/d304776c49893a7dd761ec5e68f033c3/e0885/HomeAssistant_25.png 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d304776c49893a7dd761ec5e68f033c3/e0885/HomeAssistant_25.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we choose the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`features/ptz/preset`}</code>{` MQTT Topic and send the payload value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` which will tell the camera with the MAC address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`000389888811`}</code>{` to move to `}<strong parentName="p">{`Preset Position 2`}</strong>{` (numbering of those positions starts at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{`).`}</p>
    <p>{`When you hit `}<strong parentName="p">{`Save`}</strong>{` this automation will be saved inside the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`automations.yaml`}</code>{` file inside the Home Assistant directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1571301476710'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` TEST MQTT Webhook
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` webhook
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`webhook_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt_test
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/features/ptz/preset
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command`}</code></pre></div>
    <p>{`You could test triggering the above automation by sending a `}<strong parentName="p">{`POST HTTP Request`}</strong>{` to`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`http`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`//your-home-assistant:8123/api/webhook/mqtt_test`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/eb390/HomeAssistant_26.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB/UlEQVQoz2PYtG13cHR6Wl5FdlFNcnZpZFJOdHJuVBJRiOHu/Ue79h06eOTEgcMn9h48umvfoV17D+05cGTvgaNwtHv/kR17DmIihufP33z+8v3dx89Pnr96/OzlkxevHz198fbD5w+fv0LQxy/fvnz/8R8bYHC2s3O3cfK2cvGydPKxdvYGIRdvaxcfMOlr6+ZuYpcRnfzi1Ztv3398+frt67fvcMSQaiGUqytbralarihZqSpboSpboSJTqSYLQ3KFYvxdbg4fP3z88ev3t+8/vv/4CUcMNcryNaryVapyIKQiB2dUqshWqchVqymUyYlP9PP4/PnLj5+/kHWCNENV40DV6gqlsmIT/Tx+fP/x99+/72g2E6N5kp/H718///////PHz5+/fpGp+cfPHz9+km3zz1+/fv1GaK5WV8CDajSVyuQlJgd4/f71C+LsH8h+LpYUQiApoWIpYRgCcUukRfIEOHtc7H//BDv7B6qz56UmQlFa0oLUhAVJcfOTYhekxC9MTViYmrAoI3lecsymloY/v3//////FxggNH/7/efP//9//v37+v//19v33h4+9vbkmc9nz/+8fO3HuYt/nzwDpcN/UPDt+3fkdMLw+s3bnz9/ffv2/dv3718+ff7y8dOXz1++fPr8+eMnEPvTZ0hK/Pb9BxzBNQMARzsnqYxHV2EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/e4706/HomeAssistant_26.avif 230w", "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/d1af7/HomeAssistant_26.avif 460w", "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/7f308/HomeAssistant_26.avif 920w", "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/f3790/HomeAssistant_26.avif 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/a0b58/HomeAssistant_26.webp 230w", "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/bc10c/HomeAssistant_26.webp 460w", "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/966d8/HomeAssistant_26.webp 920w", "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/c7dd1/HomeAssistant_26.webp 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/81c8e/HomeAssistant_26.png 230w", "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/08a84/HomeAssistant_26.png 460w", "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/c0255/HomeAssistant_26.png 920w", "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/eb390/HomeAssistant_26.png 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6cadfbe230b5dfbfbe2deab821b36a9/c0255/HomeAssistant_26.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Sending the command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`curl -d "" http://192.168.2.43:8123/api/webhook/mqtt_test`}</code>{` triggers the automation and sends the camera into the second preset position.`}</p>
    <p>{`Now that we know that our automation is working and the Webhook is set up, we can configure our camera to contact Home Assistant when an Alarm is triggered by the camera internal detection systems. Please open the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarmserver configuration`}</a>{` inside your camera's web user interface and add the Home Assistant Webhook to the following form. You have to activate the POST Request and add a dummy request parameter - in the screenshot below we added `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`homeassistant=alarm`}</code>{`. But this parameter can be chosen freely:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "766px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bc757556c88a9285597e4b741a1ec7d9/f7616/HomeAssistant_27.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABgUlEQVQoz42RuU4rMRSG5wGpAAndkpYXQFAxCaAI0SDRUA4ht7gVuWJJEwl6HoEqCTNZxuN4GW/HHiNPgpBAkPz6ZB3Z+o/PEu2e93eOH/+c9rabD5uNh804sNXsbTV728dfCffx3cb+342Df3sHreiij8/us/Pe5OQui2/TRjdt/M++E3fTuJse3aat+/Flf3b5zA9bVxFRfoRkinUWUKO8HExpYFLzEb+hMsMqwyot1GBWgvfXSRJxXb3lbDIXUyILDjnV07msEUtIiHOqCw6IARH2dVzmwnfa15E1BqPZHCPBmV+lqj4LppnxN8FsLUIF5xwAwK4lAPDeJ0kSzJTSqpZzzlrrVskYszQbY/I8l1JqrQHAraHPn733jDEhBOe8LMt1/FrJpdk5RwhhjHHOKaWMsaqqfhxY/TIuBJH1wLz3i5qNMYuefzEHgR0NhmMrOu12pJQqammtVy2qTkrKwcvT0A87SSeUjRDCGH/ZFMDn7pxzQggp5aI0cG7R8zsX2r9hTGOzswAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc757556c88a9285597e4b741a1ec7d9/e4706/HomeAssistant_27.avif 230w", "/en/static/bc757556c88a9285597e4b741a1ec7d9/d1af7/HomeAssistant_27.avif 460w", "/en/static/bc757556c88a9285597e4b741a1ec7d9/27214/HomeAssistant_27.avif 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bc757556c88a9285597e4b741a1ec7d9/a0b58/HomeAssistant_27.webp 230w", "/en/static/bc757556c88a9285597e4b741a1ec7d9/bc10c/HomeAssistant_27.webp 460w", "/en/static/bc757556c88a9285597e4b741a1ec7d9/3fcdd/HomeAssistant_27.webp 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc757556c88a9285597e4b741a1ec7d9/81c8e/HomeAssistant_27.png 230w", "/en/static/bc757556c88a9285597e4b741a1ec7d9/08a84/HomeAssistant_27.png 460w", "/en/static/bc757556c88a9285597e4b741a1ec7d9/f7616/HomeAssistant_27.png 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bc757556c88a9285597e4b741a1ec7d9/f7616/HomeAssistant_27.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "766px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/f7616/HomeAssistant_28.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.956521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABPElEQVQY0wExAc7+AMG+u+3q5uvo5e3q5/Lv6/Lu6/Pu6/Pu6/Tw7PXw7Pbx7PTx7PTx7fTx7fLv6+7q5+7r5+Xi3t/d2c/NywCRq8eozvemyvCmyvKqzfSpzvanz/inz/ihyvSaxPWYw/WfxvCix++hx++hx++hxu6jyPChx/CnxOykuNYAToS+VZ3qWJvkV5vkVJ3oWovTeX+6eIK9cnu4ZXG5X2y2QnrKNIXZOofaPYreP4zgP4zfNYXbWJXfgKTQAFaIv2Om7Gak5mSj52al6WWd4nGZ2HKb2W2V1l6J1k+A00iJ10mS4E6W5U6X5kuU40qS4EWR4mWk54qt0wBUiL9fo+xhouZhoudhoedSmuZgpetjp+tSmeNIkuFHkuVMlOBNleNJk+RKlORKlORKlONFjuVfnOJ/o8nhys/L9M6+dAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/e4706/HomeAssistant_28.avif 230w", "/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/d1af7/HomeAssistant_28.avif 460w", "/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/27214/HomeAssistant_28.avif 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/a0b58/HomeAssistant_28.webp 230w", "/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/bc10c/HomeAssistant_28.webp 460w", "/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/3fcdd/HomeAssistant_28.webp 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/81c8e/HomeAssistant_28.png 230w", "/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/08a84/HomeAssistant_28.png 460w", "/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/f7616/HomeAssistant_28.png 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5eb91f6d4a02dd8c5cbd7569f3d20632/f7616/HomeAssistant_28.png",
              "alt": "Home Assistant with your INSTAR IP Camera",
              "title": "Home Assistant with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now, every time an alert is triggered our camera will contact it's Alarmserver - the Home Assistant installation - and start the automation.`}</p>
    <p>{`Please check out our `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/Home_Assistant/"
      }}>{`Smarthome Overview`}</a>{` for more examples how you can automate your camera using Home Assistant. Simply switch the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT topic`}</a>{` to automate different functions on your camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      